body {
  margin: 0;
  font-family: "Hellix", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 10px;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 1900px) {
  html {
    font-size: 8.5px;
  }
}
@media screen and (max-width: 1199px) {
  html {
    font-size: 7px;
  }
}

@media screen and (max-width: 899px) {
  html {
    font-size: 9px;
  }
}

@media screen and (max-width: 599px) {
  html {
    font-size: 8px;
  }
}

@media screen and (max-width: 480px) {
  html {
    font-size: 7px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media screen and (max-width: 899px) {
  .desktopOnly {
    display: none !important;
  }
}

@media screen and (min-width: 900px) {
  .mobileOnly {
    display: none !important;
  }
}

.disabled-link {
  pointer-events: none;
}
